//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import normalHead from '@/views/components/ishop/header.vue'
  // import countryPopup from '@/components/dialogs/country.vue'
  import provincePopup from '@/views/components/ishop/dialogs/province.vue'
  import cityPopup from '@/views/components/ishop/dialogs/city.vue'
  import hospitalPopup from '@/views/components/ishop/dialogs/hospital.vue'
  import { miceService } from '@/service/miceService'
  export default {
    name: 'step0tangshi',
    watch: {
      'province': function () {
        this.data.city = '';
        this.city = '';
        sessionStorage.setItem("cityId", '');
        sessionStorage.setItem("pingRegionId",'')
        this.data.hospital = '';
        this.hospital = '';
        sessionStorage.setItem("hospitalId", '');
        this.params.cityId='';
        this.params.hospitalId='';
      },
      'city': function () {
        this.data.hospital = '';
        this.hospital = '';
        sessionStorage.setItem("hospitalId", '');
        this.params.hospitalId='';
      }
    },
    data() {
      return {
        headValue: {
          name: '餐厅查询',
          haveBack: true,
        },
        data: {
          country: '中国',
          province: '',
          city: '',
          hospital: ''
        },
        params: {
          provinceId: "",
          cityId: "",
          hospitalId: '',
        },
        province: [],
        city: [],
        hospital: [],
      }
    },
    mounted() {
       this.Bus.$on('selectProvince', item => {
        this.data.province = item.txt
        this.params.provinceId = item.regionId

      }); 
      this.data.province = sessionStorage.getItem("province")
      this.params.provinceId = sessionStorage.getItem("provinceId")

       this.Bus.$on('selectCity', item => {
        this.data.city = item.txt
        this.params.cityId = item.regionId
        
      }); 
      this.data.city = sessionStorage.getItem("city")
      this.params.cityId = sessionStorage.getItem("cityId")

      this.Bus.$on('selectHospital', item => {
        this.data.hospital = item
        this.params.hospitalId = item.rowId
      });

      this.data.hospital = sessionStorage.getItem("hospital")
      this.params.hospitalId = sessionStorage.getItem("hospitalId")

    },
    beforeDestroy() {
      this.Bus.$off('selectProvince');
      this.Bus.$off('selectCity');
      this.Bus.$off('selectHospital');
    },
    methods: {
      jump(){
        if (this.data.country && this.data.province && this.data.city && this.data.hospital) {
          let hospitalId = this.data.hospital.rowId;
          this.loadConfig(hospitalId);
        } else {
          this.toast({
            message: '请选择国家/省份/城市/医院 信息',
            duration: 2000,
          });
        }
      },
      async loadConfig(hospitalId) {
      	let params = {
      		channel:localStorage.getItem('channel') || sessionStorage.getItem('channel') || 'h5',
//    		cityId:this.params.cityId,
      		cityId:sessionStorage.getItem("pingRegionId")
      	}
        let res = await miceService.getTenantConfig(params)
	      let config = res.config.recommendConfig;//外出用餐、外卖相关配置信息
	      let shopParam = res.config.iShopParam;//医院附近餐厅条件
	      let ishopMsg = res.config.ishopSubmitMsg;//提交推荐时提示语句
	      let ishopRuleMsg = res.config.ishopRuleMsg;//医院上线基本规则
	      sessionStorage.setItem("tenantConfig", JSON.stringify(config));
	      sessionStorage.setItem("shopConfig", JSON.stringify(shopParam));
	      sessionStorage.setItem("ishopMsg", JSON.stringify(ishopMsg));
	      sessionStorage.setItem("ishopRuleMsg", JSON.stringify(ishopRuleMsg));
	      this.$router.push({name: 'Step1SelectShop', query: {id: hospitalId}})
	    },
      changeProvince(){
        let popupProvince = true
        this.Bus.$emit('changeProvince', popupProvince);
        this.getProvinceList()
      },
      changeCity(){
        let popupCity = true
        this.Bus.$emit('changeCity', popupCity);
        this.getCityList()
      },
      changeHospital(){
        let popupHospital ={
          popupHospital:true,
          pageIndex:1,
          pageSize:20,
        }
        this.Bus.$emit('changeHospital', popupHospital);
        this.getHospitalList()
      },
      getProvinceList: async function () {
        let params = {
          'regionId': "945e4101-78b8-11e6-b6c0-f80f41fdc7f8",
          'type': 1
        }
        const res = await miceService.getCityList(params)
        this.province = res.data
      },
      getCityList: async function () {
        if(!this.params.provinceId){
          return
        }
        let params = {
          regionId: this.params.provinceId,
          type: 2
        }
        const res = await miceService.getCityList(params)
        this.city = res.data
      },
      getHospitalList: async function (searchInput) {
        if(!this.params.cityId){
          return
        }
        let params = {
          cityId: this.params.cityId,
          pageSize: 20,
          pageIndex: 1,
          searchInput:searchInput?searchInput:''
        }
        const res = await miceService.getHospitalList(params)
        this.hospital = res.rows
      },
      listenToHosipital:function (searchInput) {
        this.getHospitalList(searchInput);
      }
    },
    components: {
      normalHead,
      // countryPopup,
      provincePopup,
      cityPopup,
      hospitalPopup
    }
  }
